function aowDebounce (fn, timeout = 0) {
  let flag = false;
  return function() {
    if (!flag) {
      flag = true;
      setTimeout(_ => flag = false, timeout);
      fn.call(...arguments);
    }
  };
}

function aowStagnate(f, i) {
  const delay = (i % 2) * 1000;
  setTimeout(f, delay);
}


export {
  aowDebounce,
  aowStagnate,
}