import A11yDialog from 'a11y-dialog';

class AoWDialog extends A11yDialog {
  constructor(dialog) {
    super(dialog);
  }

  show() {
    document.body.classList.toggle("js__dialog--open");
    super.show();
  }

  hide() {
    document.body.classList.toggle("js__dialog--open");
    super.hide();
  }
}

export default AoWDialog;
