class AoWSpotlightMedia {
  constructor(element) {
    this.player = null;
    this.playing = false;
    this.shuffling = false;
    // dom elements
    this.playButton = document.querySelector("#playerPlay");
    this.prevButton = document.querySelector("#playerPrev");
    this.nextButton = document.querySelector("#playerNext");
    this.shuffleButton = document.querySelector("#playerShuffle");
    this.title = document.querySelector("#playerTitle");
    // data attributes
    this.playlistId = element.dataset.playlistId || null;
    this.videoId = element.dataset.videoId || null;

    // we're attaching these to the window object so the YouTube API can access them
    window.onAoWPlayerReady = this.onPlayerReady.bind(this);
    window.onAoWPlayerError = this.onPlayerError.bind(this);
    // fire when ready
    window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady.bind(this);
  }

  setupControls() {
    this.playButton.onclick = this.togglePlay.bind(this);

    if (this.playlistId) {
      this.prevButton.onclick = this.previousVideo.bind(this);
      this.nextButton.onclick = this.nextVideo.bind(this);
      this.shuffleButton.onclick = this.toggleShuffle.bind(this);
    }
  }

  nextVideo() {
    this.player.nextVideo();
    this.toggleButtonDisplay();
    this.updateTitle();

    this.playing = true;
  }

  previousVideo() {
    this.player.previousVideo();
    this.toggleButtonDisplay();
    this.updateTitle();

    this.playing = true;
  }

  updateTitle() {
    setTimeout(_ => {
      if (this.player.videoTitle) {
        this.title.innerText = this.player.videoTitle;       
        return  this.title.style.animationDuration = `${this.title.clientWidth * 15}ms`;
      }

      this.updateTitle();
    }, 200);
  }

  toggleShuffle() {
    // toggle shuffle
    this.shuffling = !this.shuffling;
    // set shuffle on player
    this.player.setShuffle(this.shuffling);
    // auto play next video if shuffling is on
    if (this.shuffling) this.nextVideo();
  }

  toggleButtonDisplay() {
    if (this.playing) return this.playButton.innerHTML = "PAUSE";

    this.playButton.innerHTML = "PLAY";
  }

  pauseVideo() {
    this.playing = false;

    this.player.pauseVideo();
    this.toggleButtonDisplay();
    this.setShimmerEffect(false);
  }

  setShimmerEffect(on = true) {
    // we have a special shimmer for playlists - aka aow radio
    if (on && this.playlistId) return this.playButton.classList.add("shimmer--yellow");
    if (on) return this.playButton.classList.add("shimmer");

    this.playButton.classList.remove("shimmer--yellow", "shimmer");
  }

  playVideo() {
    this.playing = true;

    this.player.playVideo();
    this.setShimmerEffect();
    this.toggleButtonDisplay();
  }


  togglePlay() {
    if (!this.playing) return this.playVideo();

    this.pauseVideo();
  }

  // we're attaching these to the window object so the YouTube API can access them
  onPlayerError(e) {
    console.log(e);
    this.playButton.innerHTML = "Error";
    this.playButton.style.cursor = "not-allowed";
    this.title.innerText = "Unable to play this track.";
  }

  // we're attaching these to the window object so the YouTube API can access them
  onPlayerReady() {
    this.playButton.innerHTML = "PLAY";
    this.updateTitle();
    this.setupControls();
  }

  onYouTubeIframeAPIReady() {
    this.player = new YT.Player('player', {
      height: '200',
      width: '200',
      videoId: this.videoId,
      playerVars: this.youTubePlayerVarsSetting(),
      events: {
        'onReady': onAoWPlayerReady,
        'onError': onAoWPlayerError
      }
    });
  }

  youTubePlayerVarsSetting() {
    if (this.playlistId) {
      return {
        listType: 'playlist',
        list: this.playlistId,
        loop: 1
      }
    }

    return {
      playsinline: 1
    }
  }
}


export default AoWSpotlightMedia;
