class AoWSlideshow {
  constructor(element) {
    // class properties from DOM elements
    this.container = element;
    this.slides = this.container.getElementsByClassName("slideshow__slide");
    this.nav = this.container.querySelector(".slideshow__nav");
    // class properties
    this.activeClassName = "active";
    this.activeIndex = -1;    
    this.navLinks = this.nav.children;
    this.timeoutID = null;
    // set up the slideshow
    if (!this.navLinks.length) this.setUpNav();
    this.activateSlide();
    
  }
  // activate a slide
  activateSlide(i = null) {
    this.activateIndex(i);
    // add active class to current slide and nav link
    this.slides[this.activeIndex].classList.add(this.activeClassName);
    this.navLinks[this.activeIndex].classList.add(this.activeClassName);
    // set a timeout to run this function again
    this.timeoutID = setTimeout(this.activateSlide.bind(this), 3000); // Change image every 3 seconds
  }
  // set the activeIndex
  activateIndex(index) {
    // if the passed index is the same as the current index, do nothing
    if (index === this.activeIndex) return;
    // remove current active classes
    this.removeActive(this.slides);
    this.removeActive(this.navLinks);
    // set the active index to the passed index
    // or the next index if no index is passed
    this.activeIndex = index || this.activeIndex + 1;
    // if the active index is greater than the number of slides
    // or less than 0, reset the active index to 0
    if (this.activeIndex >= this.slides.length || this.activeIndex < 0) this.activeIndex = 0;
    // ensure activeIndex is an integer
    this.activeIndex = parseInt(this.activeIndex);
  }

  // remove active class from elements
  removeActive(elements) {
    // check if elements are defined and contain at least one element
    if (!elements || !elements.length) return;
    // if we have an index, remove the active class from the element at that index
    if (this.activeIndex >= 0) return elements[this.activeIndex].classList.remove(this.activeClassName);
    // otherwise, remove any active classes from the array elements
    for (const el of elements) {
      el.classList.remove(this.activeClassName);
    }
  }

  setUpNav() {
    if (this.slides.length < 2) return;
    // create a nav link for each slide
    for (const slide of this.slides) {
      // get the slide index from the data attribute
      const slideIndex = slide.dataset.slideshowSlide;
      // create a new nav link
      const square = document.createElement("span");
      square.className = "slideshow__nav-link";
      // bind the navClickHandler to the current link click event
      square.onclick = function () {
        this.navClickHandler(slideIndex);
      }.bind(this);
      // append the nav link to the nav
      this.nav.appendChild(square);
    }
    // set this.navLinks to the nav links
    this.navLinks = this.nav.children;
  }

  navClickHandler(slideIndex) {
    // if the timeoutID is set, clear it
    if (this.timeoutID) clearTimeout(this.timeoutID);
    // activate the slide based on slideshowSlide data attribute
    this.activateSlide(slideIndex);
  }
}

export default AoWSlideshow;
