class AoWBlankTarget {
  constructor(element) {
    this.link = element;
    this.newWindowHeight = 450;
    this.newWindowWidth = 450;
    this.events(this.target);
  }

  events() {
    this.link.addEventListener("click", (e) => {
      e.preventDefault();
      this.popUnder(this.link);
    });
  }

  popUnder(node) {
    const newWindow = window.open(
      "about:blank",
      node.target,
      `width=${this.newWindowWidth}, height=${this.newWindowHeight}`
    );
    newWindow.blur();
    window.focus();
    newWindow.location.href = node.href;
  }
}

export default AoWBlankTarget;