// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import AoWDialog from "./lib/dialog.js"
import AoWSlideshow from "./lib/slideshow.js"
import AoWBlankTarget from "./lib/blank_target.js"
import AoWSpotlightMedia from "./lib/spotlight_media.js"
import { aowStagnate } from "./lib/utilities.js"

require("fslightbox");

const aowClassElements = {
  "aow-blank-target": AoWBlankTarget,
  "aow-dialog": AoWDialog,
  "aow-slideshow": AoWSlideshow,
  "aow-spotlight-media": AoWSpotlightMedia
}


const setAoWClassElements = () => {
  const attributesNames = Object.keys(aowClassElements);

  for (const name of attributesNames) {
    const dataAttribute = `[data-${name}]`
    // query a data-attribute
    // so we don't have to sync element and element--modifier classes
    const elements = document.querySelectorAll(dataAttribute);
    // no elements, move on to the next name
    if (!elements.length) continue;

    for (const [i, e] of elements.entries()) {
      // capture the relevant class
      const eClassName = aowClassElements[name];
       
      if (name === "aow-slideshow") {
        // staganate the creation of slideshows
        // so they don't all loop in sequence
        aowStagnate(() => {
          // create Class object
          new eClassName(e)
        }, i);
        // move on to the next element
        continue;
      }
      // create Class object
      new eClassName(e);
    }
  }
}

document.addEventListener("turbo:load", () => {
  document.body.classList.replace("no-js", "js");
  setAoWClassElements();
  document.querySelectorAll("[data-fslightbox]").forEach(element => {

    element = new FsLightbox();
    refreshFsLightbox();
    });
});
